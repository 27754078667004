@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #121212;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input {
  padding: 10px 15px;
}
.ribbon {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.ribbon {
  --f: 0.5em; /* control the folded part */

  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51% / var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),
    100% 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    var(--f) calc(100% - var(--f)),
    0 100%,
    0 calc(100% - var(--f)),
    999px calc(100% - var(--f) - 999px),
    calc(100% - 999px) calc(100% - var(--f) - 999px)
  );
  transform: translate(calc((cos(45deg) - 1) * 100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  background-color: #f07818; /* the main color  */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden;
}

/* HTML: <div class="ribbon">Your text content</div> */
.bestribbon {
  font-size: 8px;
  font-weight: bold;
  color: #fff;
}
.bestribbon {
  --c: #d81a14;
  --r: 20%; /* control the cutout part */

  padding: 0.6em 1.3em;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  text-align: center;
  position: absolute;
  z-index: 0;
  width: fit-content;
  box-sizing: border-box;
  z-index: 10000;
  top: -30px;
}

.bestribbon:after {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: radial-gradient(35% 35%, #0000 96%, #0003 97% 99%, #0000) var(--c);
  clip-path: polygon(
    100% 50%,
    89.66% 55.22%,
    98.3% 62.94%,
    86.96% 65.31%,
    93.3% 75%,
    81.73% 74.35%,
    85.36% 85.36%,
    74.35% 81.73%,
    75% 93.3%,
    65.31% 86.96%,
    62.94% 98.3%,
    55.22% 89.66%,
    50% 100%,
    44.78% 89.66%,
    37.06% 98.3%,
    34.69% 86.96%,
    25% 93.3%,
    25.65% 81.73%,
    14.64% 85.36%,
    18.27% 74.35%,
    6.7% 75%,
    13.04% 65.31%,
    1.7% 62.94%,
    10.34% 55.22%,
    0% 50%,
    10.34% 44.78%,
    1.7% 37.06%,
    13.04% 34.69%,
    6.7% 25%,
    18.27% 25.65%,
    14.64% 14.64%,
    25.65% 18.27%,
    25% 6.7%,
    34.69% 13.04%,
    37.06% 1.7%,
    44.78% 10.34%,
    50% 0%,
    55.22% 10.34%,
    62.94% 1.7%,
    65.31% 13.04%,
    75% 6.7%,
    74.35% 18.27%,
    85.36% 14.64%,
    81.73% 25.65%,
    93.3% 25%,
    86.96% 34.69%,
    98.3% 37.06%,
    89.66% 44.78%
  );
}
